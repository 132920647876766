<template>
  <v-skeleton-loader
    :loading="loadingTable"
    :transition="'fade-transition'"
    height="500"
    type="table"
  >
    <v-data-table
      :headers="getHeaders(typesNotHeader)"
      :items="dataTable"
      :items-per-page="dataTable.length"
      hide-default-footer
      dense
      item-class="teste"
    >
      <template
        v-slot:body="{ items }"
      >

        <tbody>
          <tr>
            <th :colspan="getHeaders(typesNotHeader).length" v-if="typeGecom >= 2 && typeGecom < 4">Supervisor: {{ supervisor.entity_cod ? `${supervisor.entity_cod} - ${supervisor.entity_name}` : ''}}</th>
          </tr>
          <tr>
            <th :colspan="getHeaders(typesNotHeader).length" v-if="typeGecom >= 3 && typeGecom < 4">Promotor: {{ promotor.entity_cod ? `${promotor.entity_cod} - ${promotor.entity_name}` : ''}}</th>
          </tr>
          
          <tr
            v-for="item in items"
            :key="item.entity_id"
            :class="{ cancelado: isDeleted(item) }"
            class="box-table-row"
          >
            <td style="width: 100px;">
              <v-tooltip top v-if="isDeleted(item)">
                <template v-slot:activator="{ on, attrs }">
                  <span 
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate text-name"
                  >{{ item.entity_cod }} - {{ item.entity_name }}</span>
                </template>
                <span>{{ item.entity_deleted | datas('DD/MM/YYYY') }}</span>
              </v-tooltip>

              <span v-else class="text-truncate text-name">{{ item.entity_cod }} - {{ item.entity_name }}</span>
            </td>
            <td class="text-end">{{ item.deve_ant | currencyNoSymbol }}</td>
            <td class="text-end">
              <v-btn
                style="min-width: 100px;"
                elevation="1"
                color="#FFF"
                plain
                raised
                small
                tile
                @click="openProposta(item)" v-if="typeGecom === 4"
                :height="20"
              >
                {{ item.proposta | currencyNoSymbol }}
              </v-btn>

              <template v-else>
                {{ item.proposta | currencyNoSymbol }}
              </template>
            </td>
            <td class="text-end">{{ item.comissao | currencyNoSymbol }}</td>
            <!-- <td class="text-end">{{ item.liquido | currencyNoSymbol }}</td> -->
            <td class="text-end">{{ item.premio | currencyNoSymbol }}</td>
            <!-- <td class="text-end">{{ item.premios_altos | currencyNoSymbol }}</td> -->
            <!-- <td class="text-end">{{ item.lp | currencyNoSymbol }}</td> -->
            <td class="text-end">{{ item.pagto | currencyNoSymbol }}</td>
            <td class="text-end">{{ item.caixa | currencyNoSymbol }}</td>
            <td class="text-end">{{ item.limite_venda | currencyNoSymbol }}</td>
            <td class="text-end">{{ item.deve_atual | currencyNoSymbol }}</td>
            <td class="text-center">{{ item.dt_ultimo_contato | datas('DD/MM/YYYY') }}</td>
          </tr>

          <tr>
            <th>Total</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.deve_ant : '0,00' | currencyNoSymbol }}</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.proposta : '0,00' | currencyNoSymbol }}</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.comissao : '0,00' | currencyNoSymbol }}</th>
            <!-- <th class="text-end">{{ totaisGerais ? totaisGerais.liquido : '0,00' | currencyNoSymbol }}</th> -->
            <th class="text-end">{{ totaisGerais ? totaisGerais.premio : '0,00' | currencyNoSymbol }}</th>
            <!-- <th class="text-end">{{ totaisGerais ? totaisGerais.premios_altos : '0,00' | currencyNoSymbol }}</th> -->
            <!-- <th class="text-end">{{ totaisGerais ? totaisGerais.lp : '0,00' | currencyNoSymbol }}</th> -->
            <th class="text-end">{{ totaisGerais ? totaisGerais.pagto : '0,00' | currencyNoSymbol }}</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.caixa : '0,00' | currencyNoSymbol }}</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.limite_venda : '0,00' | currencyNoSymbol }}</th>
            <th class="text-end">{{ totaisGerais ? totaisGerais.deve_atual : '0,00' | currencyNoSymbol }}</th>
            <th></th>
          </tr>

          <tr>
            <th colspan="13">
              Agências:
              <span class="font-weight-regular"> Ativos:</span> {{ totaisGerais ? totaisGerais.ag_ativas : 0 }}
              <span class="ml-3 font-weight-regular"> com proposta:</span> {{ totaisGerais ? totaisGerais.com_proposta : 0 }}
              <span class="ml-3 font-weight-regular"> sem proposta:</span> {{ totaisGerais ? totaisGerais.sem_proposta : 0 }}
            </th>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: 'TabelaRelatorioGecom',
  props: {
    totaisGerais: {
      type: Object,
      default: () => {},
      required: true
    },
    dataTable: {
      type: Array,
      default: () => {},
      required: true
    },

    supervisor: {
      type: Object,
      default: () => {}
    },

    promotor: {
      type: Object,
      default: () => {}
    },

    loadingTable: {
      type: Boolean,
      default: false
    },

    typesNotHeader: {
      type: Array,
      default: () => []
    },

    typeGecom: {
      type: Number,
      default: null
    }
  },
  data: () => ({
  }),

  computed: {
    headersData () {
      return [
        { align: 'start', width: 100, class: 'table-header', cellClass: 'text-truncate', text: this.getTextHeaderTypeGecom(this.typeGecom), value: 'entity_cod' },
        { align: 'end', class: 'table-header', text: 'S.ANTERIOR', value: 'deve_ant' },
        { align: 'end', class: 'table-header', text: 'VENDA', value: 'proposta' },
        { align: 'end', class: 'table-header', text: 'COMISSÃO', value: 'comissao' },
        // { align: 'end', class: 'table-header', text: 'Líquido', value: 'liquido' },
        { align: 'end', class: 'table-header', text: 'PRÊMIOS', value: 'premio' },
        // { align: 'end', class: 'table-header', text: 'Prêmios Altos', value: 'premios_altos' },
        // { align: 'end', class: 'table-header', text: 'LP', value: 'lp' }, 
        // { align: 'end', class: 'table-header', text: 'L.P.', value: 'lp' },
        { align: 'end', class: 'table-header', text: 'SAÍDAS', value: 'pagto' },
        { align: 'end', class: 'table-header', text: 'ENTRADAS', value: 'caixa' },
        // { align: 'end', class: 'table-header', text: 'Lim. Venda', value: 'liquido' },
        { align: 'end', class: 'table-header', text: 'LIMIT. VENDA', value: 'limite_venda' },
        { align: 'end', class: 'table-header', text: 'S.ATUAL', value: 'deve_atual' },
        { align: 'center', class: 'table-header', text: 'ÚLT. DIA TRAB.', value: 'dt_ultimo_contato' },
        // { align: 'start', class: 'table-header', text: 'Últ. Dia Trab.', value: 'lastDate' }
      ]
    }
  },

  methods: {
    formatName (item) {
      return item.json_data ? `(${item.json_data.cod}) ${item.json_data.name}` : item.name
    },

    getHeaders (typesNotHeader) {
      return this.headersData.filter(item => {
        return !typesNotHeader.includes(item.value)
      })
    },

    openProposta(val) {
      this.$emit('openProposta', val)
    },

    getTextHeaderTypeGecom (typeGecom) {
      switch (typeGecom) {
        case null:
          return 'SUPERVISOR'
        case 2:
          return 'PROMOTOR'
        case 3:
        case 4:
          return 'AGÊNCIA'
        default:
          return 'AGÊNCIA'
      }
    },

    isDeleted (data) {
      if (data.entity_deleted > '1970-01-01'){
        return true
      }

      return false
    },
  }
}
</script>

<style lang="scss" scoped>
  .text-name {
    width: 150px!important;
    display: inline-block;
    line-height: 0.9;
  }

  .cadastro-usuario >>> .v-data-table__wrapper table {
    min-width: auto;
  }

  .box-table {
    .cancelado {
      color: #F00;
    }
  }
</style>